import { render, staticRenderFns } from "./index.vue?vue&type=template&id=363f9f65&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-93e13427ca/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../.yarn/__virtual__/vuetify-loader-virtual-1cdf2554cb/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VExpandTransition,VRow})
